
import Component, { mixins } from 'vue-class-component'
import { Prop, PropSync, Watch } from 'vue-property-decorator'
import InstructionGetterSetter from '../../../../../models/Overlay/InstructionGetterSetter'
import { activateMixin } from '../../../../../mixins/activateMixin'
import { KpiValue, Period } from '../../../../../../types/brief_enum'
import * as rules from '../../../../../rules/rules'
import { $BEESWAX, $FACEBOOK } from '../../../../../../config/dspConfig'
import { generalMixin } from '../../../../../mixins/generalMixin'
import {
  AttributionWindow,
  DspInstruction,
  EventOfIo,
  IoBrief,
  MeasurementToolInstruction
} from '../../../../../../types/instruction_type'
import FormTitle from '../../FormFragments/FormTitle.vue'
import ConversionFunnel from '../../FormFragments/ConversionFunnel.vue'
import { ThirdPartyKpiHelper } from '../../../../../../utils/ThirdPartyKpi/thirdPartyKpiHelper'
import { ConversionFunnelInstruction } from '../../../../../../types/brief_type'
import { checkPremepValidatorMixins } from '@/mixins/checkPremepValidatorMixins'
import PivotVariablesAutocomplete
  from '@/components/TableComponents/Form/FormFragments/3PObjectWatcher/PivotVariablesAutocomplete.vue'

@Component({
  components: {
    FormTitle,
    ConversionFunnel,
    PivotVariablesAutocomplete
  }
})
export default class BriefFunnel extends mixins(generalMixin, activateMixin, checkPremepValidatorMixins) {
  [key: string]: any // Vue.prototype variables type

  @Prop({ required: true, default: null }) insGetterSetter: InstructionGetterSetter
  @Prop({ required: true, default: false }) displayBordersFunnel: boolean
  @PropSync('untieProp', { required: true, default: {} }) untie: UntieType
  @Prop({ required: true, default: function (): Array<KpiValue> { return [] } }) kpiToOptimizeOptions: Array<KpiValue>
  @Prop({ required: true, default: false }) isKpiToOptimizeDisabled: boolean
  @Prop({ required: true, default: '' }) editedItem: IoBrief<DspInstruction>
  @Prop({ required: true, default: {} }) input: InputType
  @Prop({ default: false }) isProxyKpi: boolean

  rules = rules

  showAdvancedFunnel: boolean = false
  PCtimeUnit: string = 'minute'
  PVtimeUnit: string = 'minute'

  kpiOptions: Array<{ value: KpiValue, text: string }> = null

  readonly errorSentenceDvFeedNotSetup = `
    The DV feed has not been set-up yet, please optimize the IO using another KPI in the meantime. If it’s an unexpected status, reach out to the Partnership team.
  `

  readonly errorSentenceCanNotDetermineDvEvent = `
    We can't determine the DV event to use for this IO. Please contact the Partnership team.
  `
  readonly errorSentenceROASFeedNotSetup = `
  The ROAS feed has not been set-up yet, please optimize the IO using another KPI in the meantime.
  If it's an unexpected status, reach out to the SC team
  `

  readonly errorSentenceCanNotDetermineROASEvent = `
    We can't determine the ROAS event to use for this IO. Please contact the SC team.
  `

  readonly errorSentenceLTVFeedNotSetup = `
  The feed has not been set-up yet, please optimize the IO using another KPI in the meantime.
  If it's an unexpected status, reach out to the SC team.
  `

  readonly errorSentenceCanNotDetermineLTVEvent = `
    We can't determine the LTV event to use for this IO. Please contact the SC team.
  `

  mounted () {
    this.setPCandPVtimeUnit()
    this.setShowAdvancedFunnel()
    this.setKpiOptions()
  }

  setPCandPVtimeUnit () {
    this.PVtimeUnit = this.PVWindow % this.$MINUTES_IN_A_DAY === 0
      ? 'day'
      : 'minute'

    this.PCtimeUnit = this.PCWindow % this.$MINUTES_IN_A_DAY === 0
      ? 'day'
      : 'minute'
  }

  setShowAdvancedFunnel () {
    this.showAdvancedFunnel = !this.isAttributionWindowInDefault()
  }

  setKpiOptions () {
    let thirdPartyKpiHelper = new ThirdPartyKpiHelper()
    this.kpiOptions = this.kpiToOptimizeOptions.map((kpi: KpiValue) => {
      return { value: kpi, text: this.$commonUtils.kpiValueToKpiText(kpi) }
    }).filter((kpi: { value: KpiValue, text: string }) => {
      if (this.dsp === 'appnexus' && !this.isProxyKpi) {
        return !this.isProxyKpi || ![...thirdPartyKpiHelper.secondLvlKpi, KpiValue.CPM].includes(kpi.value)
      }

      if (this.isProxyKpi && [KpiValue.ROAS, KpiValue.LTV].includes(this.insGetterSetter.trueKpiToOptimize)) {
        return [KpiValue.CPA, KpiValue.CPA_PC].includes(kpi.value)
      }

      if (this.isProxyKpi && [KpiValue.total_engagement_index, KpiValue.total_exposure_index].includes(this.insGetterSetter.trueKpiToOptimize)) {
        return [KpiValue.CPC, KpiValue.CTR, KpiValue.VTR, KpiValue.CPCV, KpiValue.V_CPM, KpiValue.CPA, KpiValue.CPA_PC,
          KpiValue.CPM, KpiValue.CPIAVC, KpiValue.reach, KpiValue.ViewTR].includes(kpi.value)
      }
      return !this.isProxyKpi || !thirdPartyKpiHelper.secondLvlKpi.includes(kpi.value)
    })
  }

  isAttributionWindowInDefault () {
    return this.attributionWindow === 'As set in the DSP'
  }

  syncTargetKpi (resetTargetvalue = false) {
    if (!this.untie.value) {
      this.$nextTick(() => {
        this.insGetterSetter.proxyTargetForOptimizedKpi = this.insGetterSetter.proxyKpiToOptimize
        /**
         * when kpi to optimize change and untie is unchecked, reset obj_watcher.true_KPI_to_optimize.value
         */
        if (resetTargetvalue) {
          this.insGetterSetter.proxyTargetKpiValue = null
        }
      })
    }
  }

  errorsPcPvWindow () {
    if (this.kpiToOptimize === 'CPA') {
      if (Number(this.PCWindow) === 0 && Number(this.PVWindow) === 0) {
        return ['PC and PV window can\'t be equal to 0 when the Kpi to optimize is CPA']
      }
    }
    return []
  }

  errorsPcWindowOnly () {
    if (this.kpiToOptimize === 'CPA_PC') {
      if (Number(this.PCWindow) === 0 && this.attributionWindow === 'other') {
        return ['PC window can\'t be equal to 0 when the Kpi to optimize is CPA_PC']
      }
    }
    return []
  }

  errorsAttributionWindow () {
    if (this.kpiToOptimize === 'CPA_PC' && this.attributionWindow === 'As set in the DSP') {
      return ['As set in the DSP options can\t be choose when KPI is set to CPA_PC.']
    }

    return []
  }

  // used in computed for PCwindow and PVwindow
  getComputedProcessPVandPCwindow (attribute: number, timeUnit: string): number {
    if (timeUnit === 'day') {
      return attribute !== 0 ? attribute / this.$MINUTES_IN_A_DAY : attribute
    }
    return attribute
  }
  setComputedProcessPVandPCwindow (value: number, timeUnit: string): number {
    if (timeUnit === 'day') {
      return value !== 0 ? value * this.$MINUTES_IN_A_DAY : 0
    }
    return value
  }

  setUntieValueKpiSecondLvl () {
    if (this.isKpiSecondLvl && this.untie.value) {
      this.untie.value = false
    }
  }

  setDefaultOverwriteFrequencyTarget () {
    if (!this.isProxyKpi) {
      if (this.kpiToOptimize !== 'reach_and_frequency') {
        this.insGetterSetter.overwriteFrequencyTarget = false
      } else if (this.kpiToOptimize === 'reach_and_frequency') {
        this.insGetterSetter.overwriteFrequencyTarget = true
        this.insGetterSetter.targetFrequency.exposures = undefined
        this.insGetterSetter.targetFrequency.period = Period.Lifetime
      }
    }
  }

  onChangeKpiToOptimize () {
    this.setUntieValueKpiSecondLvl()
    this.setDefaultOverwriteFrequencyTarget()
    this.syncTargetKpi(true)
    this.openAdvancedFunnelIfInvalidPCWindowAndCPAPC()
  }

  openAdvancedFunnelIfInvalidPCWindowAndCPAPC () {
    if (this.kpiToOptimize === 'CPA_PC' && !this.PCWindow) {
      this.showAdvancedFunnel = true
    }
  }
  errorMessageEventFeedNotSetup () {
    const errors: string[] = []

    if (this.isProxyKpi) {
      return errors
    }
    const eventsOfIo: EventOfIo[] = this.$store.getters.getCurrentEventsOfIo
    const eventTypeName = this.getEventTypeForKpi(this.insGetterSetter.trueKpiToOptimize)

    if (this.insGetterSetter.trueKpiToOptimize === KpiValue.ROAS) {
      const roasEvents = eventsOfIo.filter((event: EventOfIo) => {
        return event.source === 'dbm' && event.event_type_name === eventTypeName && event.source_type === '3rd_party'
      })
      if (this.isKpiSecondLvl && !roasEvents.length) {
        errors.push(this.errorSentenceROASFeedNotSetup)
      } else if (this.isKpiSecondLvl && roasEvents.length > 1) {
        errors.push(this.errorSentenceCanNotDetermineROASEvent)
      }
    } else if (this.insGetterSetter.trueKpiToOptimize === KpiValue.LTV) {
      const ltvEvents = eventsOfIo.filter((event: EventOfIo) => {
        return event.source === 'progressive' && event.event_type_name === eventTypeName && event.source_type === '3rd_party'
      })
      if (this.isKpiSecondLvl && !ltvEvents.length) {
        errors.push(this.errorSentenceLTVFeedNotSetup)
      } else if (this.isKpiSecondLvl && ltvEvents.length > 1) {
        errors.push(this.errorSentenceCanNotDetermineLTVEvent)
      }
    } else {
      const dvEvents = eventsOfIo.filter((event: EventOfIo) => {
        return event.source === 'doubleverify' && event.event_type_name === eventTypeName
      })

      if (this.isKpiSecondLvl && !dvEvents.length) {
        errors.push(this.errorSentenceDvFeedNotSetup)
      } else if (this.isKpiSecondLvl && dvEvents.length > 1) {
        errors.push(this.errorSentenceCanNotDetermineDvEvent)
      }
    }
    return errors
  }
  getEventTypeForKpi (kpi: KpiValue): string {
    if (kpi === KpiValue.authentic_attention_index) {
      return 'authentic_attention'
    } else if (kpi === KpiValue.viewable_time) {
      return 'viewable_time'
    } else if ([KpiValue.ROAS, KpiValue.LTV].includes(kpi)) {
      return 'conversion_value'
    } else if (kpi === KpiValue.total_engagement_index) {
      return 'total_engagement_index'
    } else if (kpi === KpiValue.total_exposure_index) {
      return 'total_exposure_index'
    }
    return ''
  }

  errorMessageKpiOptions () {
    if (this.isWarningCPMValidator()) {
      return this.errorMessageEventFeedNotSetup()
    }
    return [...this.getErrorRelatedToFieldCPMValidator(['KPI_to_optimize']), ...this.errorMessageEventFeedNotSetup()]
  }

  getHintKpiOptions () {
    if (this.isWarningCPMValidator()) {
      const errors = this.getWarningRelatedToFieldCPMValidator(['KPI_to_optimize'])
      return errors.length ? errors.join(' ') : ''
    }
    return ''
  }

  // GETTERS
  // special rule for BEESWAX when the attribution window is displayed only when true_KPI_to_optimize === 'CPA"
  get attributionWindowBeeswaxRule (): boolean {
    return this.dsp !== $BEESWAX || this.kpiToOptimize === 'CPA'
  }
  // if true_PV_window and true_PC_window are setted, that means Attribution window are in 'other' mode
  get displayPVandPCwindow (): boolean {
    return this.attributionWindow === 'other' || this.dsp === $FACEBOOK
  }
  get isDisabledAttributionWindow (): boolean {
    return false
    // TODO : quick fix, to define with product
    // return this.kpiToOptimize !== 'CPM'
  }
  get PCwindowWithUnit () {
    return this.getComputedProcessPVandPCwindow(this.PCWindow, this.PCtimeUnit)
  }
  get PVwindowWithUnit () {
    return this.getComputedProcessPVandPCwindow(this.PVWindow, this.PVtimeUnit)
  }
  get isDisabledPVwindow (): boolean {
    return this.attributionWindow === 'other' && this.kpiToOptimize === 'CPA_PC'
  }
  // SETTERS
  set PCwindowWithUnit (value: any) {
    this.PCWindow = this.setComputedProcessPVandPCwindow(value, this.PCtimeUnit)
  }
  set PVwindowWithUnit (value: any) {
    this.PVWindow = this.setComputedProcessPVandPCwindow(value, this.PVtimeUnit)
  }

  get itemsConvMeasurementTool () {
    if (!this.isProxyKpi && this.insGetterSetter.trueKpiToOptimize === KpiValue.LTV) {
      return [
        {
          text: 'progressive',
          value: 'progressive'
        }
      ]
    }
    let items = [
      {
        text: 'DoubleVerify',
        value: 'doubleverify'
      }
    ]
    if (!this.isProxyKpi && [KpiValue.total_engagement_index, KpiValue.total_exposure_index].includes(this.insGetterSetter.trueKpiToOptimize)) {
      return items
    }
    items.push({
      text: this.dsp,
      value: this.dsp
    })
    return items
  }

  get PVWindow () {
    return this.isProxyKpi ? this.insGetterSetter.PVwindow : this.insGetterSetter.truePVwindow
  }

  set PVWindow (value: number) {
    if (this.isProxyKpi) {
      this.insGetterSetter.PVwindow = value
    } else {
      this.insGetterSetter.truePVwindow = value
    }
  }

  get PCWindow () {
    return this.isProxyKpi ? this.insGetterSetter.PCwindow : this.insGetterSetter.truePCwindow
  }

  set PCWindow (value: number) {
    if (this.isProxyKpi) {
      this.insGetterSetter.PCwindow = value
    } else {
      this.insGetterSetter.truePCwindow = value
    }
  }

  get kpiToOptimize () {
    return this.isProxyKpi ? this.insGetterSetter.kpiToOptimize : this.insGetterSetter.trueKpiToOptimize
  }

  set kpiToOptimize (value: KpiValue) {
    if (this.isProxyKpi) {
      this.insGetterSetter.kpiToOptimize = value
    } else {
      this.insGetterSetter.trueKpiToOptimize = value
    }
  }

  get attributionWindow (): AttributionWindow {
    return this.isProxyKpi ? this.insGetterSetter.attributionWindow : this.insGetterSetter.trueAttributionWindow
  }

  set attributionWindow (value: AttributionWindow) {
    if (this.isProxyKpi) {
      this.insGetterSetter.attributionWindow = value
    } else {
      this.insGetterSetter.trueAttributionWindow = value
    }
  }

  get convMeasurementTool (): MeasurementToolInstruction {
    return this.isProxyKpi ? this.insGetterSetter.convMeasurementTool : this.insGetterSetter.trueConvMeasurementTool
  }

  set convMeasurementTool (value: MeasurementToolInstruction) {
    if (this.isProxyKpi) {
      this.insGetterSetter.convMeasurementTool = value
    } else {
      this.insGetterSetter.trueConvMeasurementTool = value
    }
  }

  get formPartTitle () {
    return this.isProxyKpi ? 'learning  kpi' : 'client kpi'
  }

  get isKpiFirstLvl () {
    const thirdPartyKpiHelper = new ThirdPartyKpiHelper()
    return thirdPartyKpiHelper.isKpiFirstLvl(this.insGetterSetter.trueKpiToOptimize)
  }

  get isKpiSecondLvl () {
    const thirdPartyKpiHelper = new ThirdPartyKpiHelper()
    return thirdPartyKpiHelper.isKpiSecondLvl(this.insGetterSetter.trueKpiToOptimize)
  }

  get displayProxyKpi () {
    return !this.isProxyKpi && this.isKpiSecondLvl
  }

  get conversionFunnelPixelIds () {
    return this.isProxyKpi
      ? this.insGetterSetter.conversionFunnelPixelIds
      : this.insGetterSetter.trueConversionFunnelPixelIds
  }

  set conversionFunnelPixelIds (value: ConversionFunnelInstruction) {
    if (this.isProxyKpi) {
      this.insGetterSetter.conversionFunnelPixelIds = value
    } else {
      this.insGetterSetter.trueConversionFunnelPixelIds = value
    }
  }

  get displayUntie () {
    return this.activateUntie && !this.isProxyKpi && this.isKpiFirstLvl
  }

  get isRequiredPivotVariable () {
    return this.isKpiSecondLvl
  }

  get isReadOnlyConversionMeasurementTool () {
    return [KpiValue.ROAS, KpiValue.LTV, KpiValue.total_engagement_index, KpiValue.total_exposure_index].includes(this.insGetterSetter.trueKpiToOptimize)
  }

  // WATCHERS
  @Watch('isDisabledPVwindow', { immediate: true })
  onIsDisabledPVwindowChange (value: boolean) {
    if (value) {
      this.PVWindow = 0
    }
  }
}

