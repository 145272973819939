import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

import { MarkupModuleModel } from '../models/Keystone_v2/MarkupModuleModel'
import InstructionGetterSetter from '../models/Overlay/InstructionGetterSetter'
import { DspInstruction, InstructionDsp, IoBrief } from '../../types/instruction_type'
import SiegeModel from '../models/Keystone_v2/SiegeModel'
import * as rules from '../rules/rules'
import SiegeSettingModel from '@/models/Keystone_v2/SiegeSettingModel'
import { $BEESWAX, $DBM, $THETRADEDESK, $YOUTUBE } from '../../config/dspConfig'
import _ from 'lodash'
import { isNotNullOrUndefined } from '../../utils/commonUtils'

export type ItemArrayType = Array<TextValueType<string>>

@Component
export class mrkpModuleMixin extends Vue {
  @Prop() dsp: InstructionDsp

  @Prop({ required: false, default: null }) insGetterSetter: InstructionGetterSetter
  @Prop({ required: false, default: function (): MarkupModuleModel { return new MarkupModuleModel() } }) inheritedMarkupModule: MarkupModuleModel

  // KEYSTONE ONLY
  @Prop({ required: false, default: null }) model: MarkupModuleModel

  // SURCOUCHE ONLY
  @Prop({ required: false, default: null }) editedItem: IoBrief<DspInstruction> // IO Brief item => editedItem.markup_module == model
  @Prop({ required: false, default: null }) inheritedSiege: SiegeModel // Inherited siege to fill form with

  @Prop({ required: false, default: null }) keystoneSettingSiege: SiegeSettingModel

  @Prop({ required: true }) source: 'keystone' | 'surcouche'
  rules = rules

  // Model entity, default is a new one
  markupModule: MarkupModuleModel = new MarkupModuleModel()

  granularityItems: ItemArrayType = [
    { text: 'Insertion Order', value: 'insertion_order_id' },
    { text: 'Line Item', value: 'line_item' }
  ]
  modificationModeItems: ItemArrayType = [
    { text: 'Automated', value: 'automatic' },
    { text: 'Manual', value: 'manual' }
  ]

  disabledItemsModificationMode (item: TextValueType<string>) {
    return [$BEESWAX, $THETRADEDESK].includes(this.dsp as InstructionDsp) && item.value === 'automatic'
  }

  setUp () {
    if (this.isKeystoneForm) {
      this.setUpKeystoneMarkupModule()
    } else if (this.isSurcoucheForm) {
      this.setUpSurcoucheMarkupModule()
    }
  }

  setUpKeystoneMarkupModule () {
    this.markupModule = this.model != null ? this.model : new MarkupModuleModel()
    if (this.markupModule.useBillingPeriodAsKpiEvalPeriod) {
      this.markupModule.kpiEvalPeriodInDays = null
    }
    if (this.markupModule.useBillingPeriodAsAvgMrkpEvalPeriod) {
      this.markupModule.avgMrkpEvalPeriodInDays = null
    }
  }

  setUpSurcoucheMarkupModule () {
    // Do not start process if entites are being loaded in parent
    if (this.editedItem != null) {
      // Default is true. If no siege is found to be linked, put it to false to prevent bad behaviours
      if (this.inheritedSiege == null || this.insGetterSetter.markupManagementIsInheritedFromSeat === false ||
        (isNotNullOrUndefined(this.inheritedMarkupModule) && this.inheritedMarkupModule.action === 'media_savings' && this.dsp === $DBM)) {
        this.insGetterSetter.markupManagementIsInheritedFromSeat = false
        this.setUpSurcoucheNotInheritedMarkupModule() // Markup Module is NOT inherited from Seat
      } else if (this.inheritedSiege && this.insGetterSetter.markupManagementIsInheritedFromSeat) {
        this.setUpSurcoucheInheritedMarkupModule() // Markup Module is inherited from Seat
      }
    }
  }

  setUpSurcoucheNotInheritedMarkupModule () {
    this.markupModule = this.editedItem.markup_module != null
      ? new MarkupModuleModel(this.editedItem.markup_module)
      : new MarkupModuleModel()
  }

  setUpSurcoucheInheritedMarkupModule () {
    this.markupModule = this.inheritedMarkupModule
      ? new MarkupModuleModel(this.inheritedMarkupModule.getObjectForApi())
      : new MarkupModuleModel()
  }

  // RESET
  resetMarkupModule () {
    if (this.isSurcoucheForm) {
      this.markupModule.setToDefaultValues()
    } else if (this.isKeystoneForm) {
      this.setUpKeystoneMarkupModule()
    }
  }

  removeMarkupModuleSeatInheritance () {
    this.insGetterSetter.markupManagementIsInheritedFromSeat = false
  }

  // LABELS
  // Same in both
  get minimumLabel () { return 'Minimum' }
  get maximumLabel () { return 'Maximum' }
  get useFirstPushMrkpAsMinNominalLabel () { return 'Use markup present at moment of 1st Model push' }
  get kpiEvalPeriodInDaysLabel () { return '<strong>Rolling period</strong> during which the rev KPI value should stay below the KPI target defined in the surcouche' }
  get avgMrkpEvalPeriodInDaysLabel () { return '<strong>Rolling Period</strong> during which the Average Markup should not exceed its Max value' }
  get controlMrkpAtPofLvlLabel () { return 'Maintain Average daily markup at Campaign Level' }
  // Keystone
  get maxAvgMrkpDuringPeriodLabel () { return 'Max. Average Markup' }

  errorMessagesAvgMrkpEvalPeriodInDays () {
    if (!this.markupModule.useBillingPeriodAsAvgMrkpEvalPeriod) {
      return this.rules.isBetween(Number(this.markupModule.avgMrkpEvalPeriodInDays), 1, 90, 'Value must be between 1 and 90')
    }
    return ''
  }
  errorMessagesKpiEvalPeriodInDays () {
    if (!this.markupModule.useBillingPeriodAsKpiEvalPeriod && !this.markupModule.kpiEvalPeriodInDays) {
      return 'Field is required'
    }
    return this.rules.isBetween(Number(this.markupModule.kpiEvalPeriodInDays), 0, 90, 'Value must be between 0 and 90')
  }
  errorMessagesMaxNominalMrkp () {
    if (!this.markupModule.maxNominalMrkp || this.markupModule.maxNominalMrkp === '') {
      return 'Field is required'
    }
    return this.rules.isBetween(Number(this.markupModule.maxNominalMrkp), 0, 8000, 'Value must be between 0 and 8000%')
  }
  errorMessagesMaxAvgMrkpDuringPeriod () {
    if (this.markupModule.action === 'margin' && !this.markupModule.maxAvgMrkpDuringPeriod) {
      return `Required if Action = Margin (and not Ratio)`
    }
    return this.rules.isBetween(Number(this.markupModule.maxAvgMrkpDuringPeriod), 0, 4000, 'Value must be between 0 and 4000%')
  }
  errorMessagesCampaignMinMrkpCost () {
    return this.markupModule.controlMrkpAtPofLvl && !this.markupModule.avgDailyMrkpAtPofLvl ? `Required if "${this.controlMrkpAtPofLvlLabel}" is ON` : ''
  }

  // CUSTOM RULES
  ruleControlPeriod (v: any, isBillingPeriodUsed: boolean) {
    return ((!isNaN(v) && this.$commonUtils.countDecimal(Number(v)) === 0) || isBillingPeriodUsed === true || !this.isActive) || 'This value must be a numeric value without decimals'
  }

  ruleMediaSavingStateMustBeCustom (v: any, state: string|null) {
    if (v === 'media_savings') {
      return (state === 'custom') || '“Media Savings” requires custom state, either change the action value or the module state'
    }
    return true
  }

  ruleMediaSavingModificationModeMustBeAutomatic (v: any, modificationMode: string|null) {
    if (v === 'media_savings') {
      return (modificationMode === 'automatic') || '“Media Savings” requires automatic modification mode'
    }
    return true
  }

  ruleMediaSavingGranularityMustBeIoLevel (v: any, granularity: string|null) {
    if (v === 'media_savings') {
      return (granularity === 'insertion_order_id') || '“Media Savings” requires IO level granularity'
    }
    return true
  }

  markupToMargin (markupValue: number): number {
    const nbDigit = 6
    const markupValueRatio = markupValue / 100
    const margin = (markupValueRatio / (1 + markupValueRatio)) * 100
    return this.$commonUtils.roundNum(margin, nbDigit)
  }

  // GETTERS
  get isSurcoucheForm () {
    return this.source === 'surcouche'
  }
  get isKeystoneForm () {
    return this.source === 'keystone'
  }
  get isActive () {
    const surcoucheConditional = this.isSurcoucheForm ? !this.insGetterSetter.markupManagementIsInheritedFromSeat : true
    return this.markupModule.state === 'custom' && surcoucheConditional
  }
  get isActiveKeywords () {
    const surcoucheConditional = this.isSurcoucheForm ? !this.insGetterSetter.markupManagementIsInheritedFromSeat : true
    return this.markupModule.state !== 'off' && surcoucheConditional
  }

  get isUserDebugger () {
    return this.$commonUtils.hasUserCorrectStatus(this.$store.getters.getUserStatus, 'isDebugger')
  }

  get isMediaSavings () {
    return this.markupModule.action === 'media_savings'
  }

  get isMediaSavingsOptionOpen () {
    return (((this.dsp === $YOUTUBE) || ((this.dsp === $DBM) && (this.isKeystoneForm))) &&
        (this.markupModule.state === 'custom') &&
        (this.markupModule.modificationMode === 'automatic') &&
        (this.markupModule.granularity === 'insertion_order_id')) ||
      this.isMediaSavings
  }

  get actionItems (): ItemArrayType {
    let items: ItemArrayType = [
      { text: 'Ratio', value: 'ratio' },
      { text: 'Margin', value: 'margin' }
    ]
    if (this.isMediaSavingsOptionOpen) {
      items.push({
        text: 'Media Savings',
        value: 'media_savings'
      })
    }
    return items
  }

  changeMarkupModuleState (value: string) {
    if (this.markupModule.state !== 'custom' && value === 'custom') {
      if (this.markupModule.state === 'weekly_alerts') {
        // if previous state is weekly_alerts, we want to keep the value of markupFeeDesignationKeywords
        const markupFeeDesignationKeywords = this.markupModule.markupFeeDesignationKeywords
        this.markupModule.setToDefaultValues()
        this.markupModule.markupFeeDesignationKeywords = markupFeeDesignationKeywords
      } else {
        this.markupModule.setToDefaultValues()
      }
    }
    this.markupModule.state = value
  }

  // WATCHERS
  @Watch('markupModule.useFirstPushMrkpAsMinNominal')
  onUseFirstPushMrkpAsMinNominalChange (value: boolean) {
    if (value) { this.markupModule.minNominalMrkp = null }
  }
  @Watch('markupModule.useBillingPeriodAsAvgMrkpEvalPeriod')
  onUseBillingPeriodAsAvgMrkpEvalPeriodChange (value: boolean) {
    if (value) { this.markupModule.avgMrkpEvalPeriodInDays = null }
  }
  @Watch('markupModule.useBillingPeriodAsKpiEvalPeriod')
  onUseBillingPeriodAsKpiEvalPeriodChange (value: boolean) {
    if (value) { this.markupModule.kpiEvalPeriodInDays = null }
  }
  @Watch('markupModule.action')
  onMarkupModuleActionChange (value: string) {
    this.markupModule.maxAvgMrkpDuringPeriod = value === 'ratio' ? null : this.markupModule.maxAvgMrkpDuringPeriod
  }

  @Watch('markupModule.action')
  onMarkupModuleActionIsMediaSavings (value: string) {
    if (value === 'media_savings') {
      this.markupModule.useBillingPeriodAsKpiEvalPeriod = true
    }
  }
}
